<template>
    <div class="coming-soon">
      <h1 :class="{'fade-in': isVisible}">Coming Soon</h1>
      <p :class="{'fade-in': isVisible}" style="animation-delay: 1s;">
        My website is under construction. Stay tuned!!
        
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ComingSoon',
    data() {
      return {
        isVisible: false,
      };
    },
    mounted() {
      setTimeout(() => {
        this.isVisible = true;
      }, 500); // Delay for animation
    },
  };
  </script>
  
  <style scoped>
  .coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  h1, p {
    margin: 0;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease-in-out;
  }
  
  h1.fade-in, p.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  p {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  </style>
  