<template>
  <div class="container p-4">
    <loading-overlay :show="isLoading" />
    <div v-if="teamMember" class="bg-dark text-white shadow-lg rounded p-6">
      <h1 class="display-3 mb-4">{{ teamMember.title.rendered }}</h1>

     
      <p v-html="teamMember.content.rendered" class="mb-6 text-light"></p>

      <h4 class="display-4 mb-2">Skills:</h4>
      <ul class="list-unstyled mb-6">
        <li v-for="(skill, index) in teamMember.acf.skills_copy" :key="index">
          <span class="font-weight-bold">{{ skill.skill_name2 }}</span>
        </li>
      </ul>

      <h2 class="display-4 mb-2">Social Links:</h2>
      <ul class="list-inline">
        <li v-for="(link, platform) in teamMember.acf.social" :key="platform" class="list-inline-item mb-2">
          <a :href="link" target="_blank" v-if="link" class="text-info">
            <font-awesome-icon :icon="getIcon(platform)" class="mr-2" /> {{ platform }}
          </a>
        </li>
      </ul>
    </div>
    <p v-else-if="!isLoading" class="text-danger">No data available.</p>
  </div>
</template>

<script>
import $ from 'jquery';
import { fetchTeamMember } from "@/services/apiService";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
//import LottieComponent from "@/components/LottieComponent.vue";

// Import Font Awesome libraries
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add icons to the library
library.add(faFacebook, faTwitter, faInstagram);

export default {
  data() {
    return {
      teamMember: null,
      isLoading: true,
    };
  },
  components: {
    LoadingOverlay,
   
    FontAwesomeIcon
  },
  async created() {
    try {
      this.teamMember = await fetchTeamMember();
      console.log("Team Member Data:", this.teamMember); // Logs the JSON response
    } catch (error) {
      console.error("Error loading team member data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    $(document).ready(function(){
      // Place your jQuery code here
    });
  },
  methods: {
  getIcon(platform) {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return ['fab', 'facebook'];
      case 'twitter':
        return ['fab', 'twitter'];
      case 'instagram':
        return ['fab', 'instagram'];
      case 'linkedin':
        return ['fab', 'linkedin-in']; // Update to 'linkedin'
      default:
        return ['fas', 'link']; // Fallback to a generic link icon
    }
  }
}

//<font-awesome-icon :icon="['fab', 'linkedin-in']" />
};
</script>

<style scoped>
body {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
}

#app {
  min-height: 100vh; /* Ensure the app covers the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-dark {
  background-color: #000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-info {
  color: #17a2b8 !important;
}

.display-4 {
        font-size: 1.5rem;
        font-weight: bold;
}

</style>
