import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '@/views/HomeView.vue';
 import home from '@/views/HomeView.vue';
import ComingSoon from '@/views/ComingSoon.vue';

const routes = [
  {
    path: '/',
    name: 'ComingSoon',
    component: ComingSoon,
    meta: { title: 'CzarDev' },
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: { title: 'CzarDev' },
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


// Set the document title when navigating to a new route
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';
  next();
});

export default router;