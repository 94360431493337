import axios from 'axios';

const API_URL = 'https://cdn.mydevhouse.dev/wp-json/wp/v2/team_member/727';

export const fetchTeamMember = async () => {
  try {
    const response = await axios.get(API_URL);
   
    return response.data;
  } catch (error) {
    console.error('Error fetching team member data:', error);
    throw error;
  }
};


const logTeamMemberData = async () => {
  try {
    // const data = await fetchTeamMember();
    // console.log('Team member data:', data);
  } catch (error) {
    console.error('Error fetching team member data:', error);
  }
};

logTeamMemberData();
